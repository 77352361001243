function Post12() {
    return (
      
        <div style={{width: "50%"}}>
          <b>meaningless:</b> we are meaningless. turning inwards to find meaning will only arrive at dilusion and narcissism. meaning is crafted through service. orient yourself entirely towards something or someone you love and along the path to get there your meaning and purpose will emerge. modern, western, 'spirituality' is leveraging ego for profit, selling courses, practices, and communities with the promise of a better life. it's just another market to be made. anything under a capitalist state will be capitalized on. 
      </div>
  
    
    );
  
  }
  
  export default Post12;