function Post9() {
    return (
      
        <div style={{width: "50%"}}>
          <b>love, community, service:</b> it's all i'm chasing. it's why i write, think, and act.
          love, community, and service, are where i find my meaning. turning inwards i find emptiness.
          a void waiting to be filled with all that rests beyond. only in directing myself towards those i love
          can i understnad myself. in isolation i carry no meaning nor purpose, except possibly to exist. but i am
          no monk. i desire to act, and feel, and play with this life i've been gifted. an animal. i am simple.
          
      </div>
  
    
    );
  
  }
  
  export default Post9;