import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

function Post2() {
  return (

      <div style={{width: "50%"}}>
        <b>true warriors</b>: True warriors have no enemies
    </div>


  );

}

export default Post2;