import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

function Post4() {
  return (

      <div style={{width: "50%"}}>
        <b>high effort side quests</b>: i heard this in a chaotic presentation the other day. it implies a strong protagonistic way of being while also having the ability to step away from your main mission. often we get consumed by our little human games, and some people even have a hard time putting their finger on which games they're part of. this is a HESQ.
    </div>


  );

}

export default Post4;