function Post11() {
    return (
      
        <div style={{width: "50%"}}>
          <b>publishers:</b> gate keepers of knowledge and progress. publishers pay-wall front-line knowledge for profit. they take advantage of researchers, drain the pockets of universities, and all for personal gain. academic research should be default open-access. fuck the oligopoly.
      </div>
  
    
    );
  
  }
  
  export default Post11;