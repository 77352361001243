import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

function Post1() {
  return (
    
      <div style={{width: "50%"}}>
        <b>rapidy fucking around</b>: rapily fucking around.... there's an art to the way in which we move ideas into reality. and sometimes... a lack there of. many will say that life is about the journey and not the destination. i say life is about how rapidy you can fuck around.
    </div>

  
  );

}

export default Post1;